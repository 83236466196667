import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, Icon } from "@material-ui/core";
import withWidth from '@material-ui/core/withWidth';
import Layout from "../components/layout";
// import SEO from '../components/SEO'
import NotaCard from "../components/NotaCard";
import Link from 'gatsby-link';
import conferenciaBanner from '../assets/tags/CONF_SECCION.png';
import SEOCategory from '../components/SEOCategory';
// import bannerAgenda from '../assets/banners/agenda/agenda.jpeg';
import VideoCard from "../components/VideoCarousel";


const useStyles = makeStyles(theme => ({
    boxContenedor:{
        position:'relative',
        textAlign:"center",
        margin:"2rem",
    },
    pageControls:{
        marginTop:"1rem",
        display:"flex",
        justifyContent:"center",
    },
    boxCenterControls:{
        width:"10rem",
        position:"relative",
    },
    infoControls:{
        width:"50%",
    },
    boxPrevPage:{
        position:"absolute",
        left:"0",
    },
    boxNextPage:{
        position:"absolute",
        right:"0",
        top:"0",
    },
    gridNotaItem:{
    },
    tagBanner:{
        width: 'auto',
        height: '17rem',
        [theme.breakpoints.down('md')]: {
            height: '13.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            height: '11.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            height: '5rem',
        },
    },
    responsiveGrid: {
      width: "90%",
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "1rem",
      [theme.breakpoints.up('md')]: {
        marginLeft:"auto",
        marginRight: "auto",
        gridTemplateColumns: "1fr 1fr 1fr",
      },
    },
    iframeContainer: {
      marginLeft: "-10px",
    },
    destacada: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    videosContainer: {
      display: 'flex',
      justifyContent: "center",
      marginTop: "1rem",
      marginBottom: "1rem",
    }
}));

function ListaNotas({notas, imgFluidDefault}) {
    const classes = useStyles();
    return(
        <Box className={classes.boxContenedor} >
            <Grid container spacing={2}>
            {
              notas.map(nota => (
                <Grid key={`listanotas-${(Math.random()*100)+1}`} item xs={12} md={4} className={classes.gridNotaItem} >
                    <NotaCard node={nota} imgFluidDefault={imgFluidDefault} />
                </Grid>
              ))
            }
            </Grid>
        </Box>
    );
}

function EncabezadoPage({titulo}) {
    const classes = useStyles();
    switch (titulo) {
      case "segundo-congreso-nacional-politica-obrera":
        return(
          <Box>
            <Box className={classes.boxContenedor}>
              <Typography component="h1">
                <b>{`Segundo Congreso Nacional de Política Obrera`}</b>
              </Typography>
            </Box>
          </Box>
        )
      case "guerra-mundial-documentacion":
        return(
          <Box>
            <Box className={classes.boxContenedor}>
              <Typography component="h1">
                <b>{`La Guerra Mundial - Documentación`}</b>
              </Typography>
            </Box>
          </Box>
        )
      case "Segunda Conferencia Nacional":
        return(
          <Box>
            <Box className={classes.boxContenedor}>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <img className={classes.tagBanner} src={conferenciaBanner} alt="Segunda Conferencia Nacional Partido Obrero Tendencia" />    
              </Box>
            </Box>
          </Box>
        )
      case "Primer Congreso Política Obrera":
        return(
          <Box>
            <Box className={classes.boxContenedor}>
              <Typography component="h1">
                <b>{`Primer Congreso de Política Obrera (Partido Obrero Tendencia)`}</b>
              </Typography>
            </Box>
          </Box>
        )
      case "iii-evento-trotsky":
        return(
          <Box>
            <Box className={classes.boxContenedor}>
              <Typography variant='h4'>
                <b>{`III Evento León Trotsky | Buenos Aires 2024`}</b>
              </Typography>
            </Box>
          </Box>
        )
      default:
        return(
          <Box>
            <Box className={classes.boxContenedor}>
              <Typography component="h1" style={{fontSize:"2rem"}}>
                {/* TODO: REVISAR ESTO */}
                <b>{titulo.replace('"','').replace('-',' ')}</b>
              </Typography>
            </Box>
          </Box>
        )
    }
}

function Paginado({currentPage, numPages, tagSlug}) {
    const classes = useStyles();
    return(
        <Box className={classes.boxContenedor} >
            <Box className={classes.pageControls}>
                <Box className={classes.boxCenterControls}>
                    {currentPage > 1
                        &&  <Box className={classes.boxPrevPage}>
                                <Link
                                    to={`/tag/${tagSlug}/${(currentPage - 1) > 1 ? currentPage - 1 : ''}`}>
                                    <Icon>keyboard_arrow_left</Icon>
                                </Link>
                            </Box>
                    }
                    <Typography className={classes.infoControls} component="span">
                        <b>{`${currentPage}/${numPages}`}</b>
                    </Typography>
                    {currentPage < numPages
                        &&  <Box className={classes.boxNextPage}>
                                <Link
                                    to={`/tag/${tagSlug}/${currentPage + 1}`}
                                >
                                    <Icon>keyboard_arrow_right</Icon>
                                </Link>
                            </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

function InfiniteScroll({items, currentPage, numPages, tagSlug, imgFluidDefault}) {
    // cargamos de a 6 elementos
    const [ hasMore, setMore ] = useState(items.length > 6)
    const [ currentList, setCurrentList ] = useState([...items.slice(0, 6)])

    const loadEdges = () => {
      const currentLength = currentList.length
      const more = currentLength < items.length
      const nextEdges = more ? items.slice(currentLength, currentLength + 6) : []
      setMore(more)
      setCurrentList([...currentList, ...nextEdges])
    }


    useEffect(() => {
        const handleScroll = () => {
          if ( !hasMore ) return;
          if ( window.innerHeight + document.documentElement.scrollTop >= (document.documentElement.offsetHeight - 5) ){
            loadEdges();
          }
        }
        window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    });

    return (
      <>
        <ListaNotas notas={currentList} imgFluidDefault={imgFluidDefault} />
        {
          !hasMore &&
            <Paginado
                tagSlug={tagSlug}
                numPages={numPages}
                currentPage={currentPage}
            />
        }
        {
          hasMore &&
            <div>Escrollea hacia abajo para cargar mas...</div>
        }
      </>
    )
  }

  const videos = [
    {
      link:'https://www.youtube.com/embed/tuBb2XXS9Fs',
      title:'Por qué llaman a votar en blanco Parte 1',
    },
    {
      link:'https://www.youtube.com/embed/BTu1SbvfuWY',
      title:'Por qué llaman a votar en blanco Parte 2',
    },
    {
      link:'https://www.youtube.com/embed/vUcJraKJGkw',
      title:'Por qué llaman a votar en blanco Parte 3',
    },
    {
      link:'https://www.youtube.com/embed/dI3Ex4AvR4o',
      title:'Por qué llaman a votar en blanco Parte 4',
    },
  ]

function Tag(props) {
    const classes = useStyles();
    const notas = props.data.allMarkdownRemark.edges.map(item => item.node);
    const imgFluidDefault = props.data.file.childImageSharp.fluid;
    const { tag, numPages, currentPage, tagSlug } = props.pageContext;
    const notaDestacada = tagSlug === "voto-en-blanco" ? notas?.filter((item) => item.fields.slug === "10572-voto-en-blanco-no-solo-principista-sino-estrategico")[0] : null

    return (
        <Layout>
            <SEOCategory category={tag} slug={tagSlug} tag={true}/>
            <EncabezadoPage
                titulo={tag}
            />
            {
              tagSlug === "voto-en-blanco" && (
                <div style={{display: "flex", width: "100%"}}>
                  <div style={{backgroundColor: "lightgray"}}>
                    <div className={classes.responsiveGrid}>
                      <div className={classes.videosContainer}>
                        {/* <img
                          src={bannerAgenda}
                          style={{width: "400px"}}
                          alt="Acto: La izquierda verdadera vota en blanco! Hablan Jorge Altamira y Patricia Urones. Salón La Argentina. Rodriguez Peña 361, CABA"
                        /> */}
                      </div>
                      <div className={classes.iframeContainer}>
                        <VideoCard 
                          data={videos}
                        />
                      </div>
                      <div className={classes.destacada}>
                        <NotaCard node={notaDestacada}/>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {notas.length > 0 && <InfiniteScroll
                                    items={notas}
                                    imgFluidDefault={imgFluidDefault}
                                    autorSlug={tagSlug}
                                    numPages={numPages}
                                    currentPage={currentPage}
                                />
            }
            {notas.length === 0 && (
                <Box className={classes.boxContenedor} >
                    <Typography component="p">
                        <b>No hay notas por el momento para este tag.</b>
                    </Typography>
                </Box>
            )}
        </Layout>
    );
}


export const query = graphql`
    query($tag: String, $skip: Int, $limit: Int) {
        allMarkdownRemark(
            filter: {frontmatter: {tags: {eq: $tag}}}
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ){
            edges{
                node{
                    frontmatter {
                        title
                        image
                        author
                        date
                        seccion
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        file(sourceInstanceName: {eq: "defaults"}) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

export default withWidth()(Tag);
